import { useCallback } from 'react';
import RunnerRunService from '../../../../services/runnerRun/RunnerRunService';
// import { call } from 'redux-saga/effects'
import { useOrganizationId } from '../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../state/hooks/WorkspaceHooks';

export const useDatastoreItemDetails = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return {
    downloadRunLogs: useCallback(
      (runnerId, runnerRunId) => RunnerRunService.downloadLogsFile(organizationId, workspaceId, runnerId, runnerRunId),
      [organizationId, workspaceId]
    ),
  };
};
