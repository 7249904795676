export default `
graph TD
SITE(Site Géographique)
PE(Poste Electrique)
C(Cellule)
MDT(Moyen de transformation)

CC(Controle Commande)
DISJ(Disjoncteurs)
SECT(Sectionneur)
TDM(Transformateur de Mesure)
TDP(Transformateur de Puissance)

POLE(Pole)
EC(Element Central)
COMM(Commande)
TR(Traversee)
BOB(Bobine)
REG(Regleur)


SITE-->PE
PE-->C
PE-->MDT
C-->CC
C-->DISJ
C-->SECT
C-->TDM
MDT-->TDP

DISJ-->POLE
DISJ-->EC
DISJ-->COMM

TDP-->TR
TDP-->BOB
TDP-->REG


style SITE fill:#F1948A,stroke:#E74C3C
style PE fill:#F1948A,stroke:#E74C3C
style C fill:#F1948A,stroke:#E74C3C
style MDT fill:#F1948A,stroke:#E74C3C

style CC fill:#85C1E9,stroke:#3498DB
style DISJ fill:#85C1E9,stroke:#3498DB
style SECT fill:#85C1E9,stroke:#3498DB
style TDM fill:#85C1E9,stroke:#3498DB
style TDP fill:#85C1E9,stroke:#3498DB

style POLE fill:#F9E79F,stroke:#D4AC0D
style EC fill:#F9E79F,stroke:#D4AC0D
style COMM fill:#F9E79F,stroke:#D4AC0D
style TR fill:#F9E79F,stroke:#D4AC0D
style BOB fill:#F9E79F,stroke:#D4AC0D
style REG fill:#F9E79F,stroke:#D4AC0D


`;
